<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DATA OUTGOING LIST OF ITEM DETAILS</title>
    <section class="content-header">
      <h1>
        Update Data Outgoing List of Item Details
        <br />
        <h4>
        Please enter the transaction data for the detailed list of item outgoing
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Item Outgoing List Transaction details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Detailed Item Outgoing List Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Outgoing Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="outgoingno"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxoutgoingd"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxpenerimaand"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="number"
                    v-model="jml"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="catatan"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/outgoing">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updt",
  data() {
    return {
      list_paged: [],
      loading: false,
      isidata: {
        outgoingno: "",
        idxoutgoingd: "",
        jml: "",
        catatan: "",
        idxpenerimaand: ""
      }
    };
  },
  created() {
    this.loadData();
    this.isidata.idxoutgoingd = this.$route.params.id;
  },
  methods: {
    loadData() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl +
        "outgoing_d/getoutgoing_dbyid?id=" +
        this.$route.params.id;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Outgoing Number not found",
              showConfirmButton: false
            });
            this.outgoingno = "";
          } else {
            this.outgoingno = resp.data.data.outgoing_number;
            this.idxoutgoingd = resp.data.data.id;
            this.jml = resp.data.data.qty;
            this.catatan = resp.data.data.notes;
            this.idxpenerimaand = resp.data.data.id_penerimaan;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    async updateData() {
      this.loading = true;
      var noout = this.outgoingno;
      var idxdoutgoing = this.idxoutgoingd;
      var catat = this.catatan;
      var jmlp = this.jml;

      if (noout == "" || idxdoutgoing == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Outgoing Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jmlp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          outgoing_number: this.outgoingno,
          id_penerimaan: this.idxpenerimaand,
          notes: this.catatan,
          qty: this.jml,
          kode_user: kodeuser
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata = this.$apiurl + "outgoing_d/updateoutgoing_d/" + idxdoutgoing;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIsavedata, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              //   this.$router.push({ name: "outgoingheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              //   this.$router.push({ name: "outgoingheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
